<template>
  <div class="marketplace-attributes">
    <MarketplaceAttribute v-for="item in attributes" :key="item.id" :value="attributeModel(item.attribute.id)" :attribute="item" @input="updateAttributeValue" />
    <div class="marketplace-attributes__actions">
      <v-btn color="primary" elevation="0" :disabled="isSubmitting" @click="$emit('submit')">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import MarketplaceAttribute from '@/components/FeedItem/MarketplaceAttributes/MarketplaceAttribute';

export default {
  components: {
    MarketplaceAttribute,
  },
  props: {
    value: { type: Array, required: true },
    attributes: { type: Array, required: true },
    isSubmitting: { type: Boolean, default: false },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    attributeModel(attributeId) {
      return this._value.find((item) => item.attribute === attributeId);
    },
    updateAttributeValue(data) {
      const toUpdateIndex = this._value.findIndex((item) => item.attribute === data.attribute);

      this._value[toUpdateIndex] = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.marketplace-attributes {
  margin-left: auto;
  margin-right: auto;
  max-width: 480px;

  &__actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
