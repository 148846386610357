<template>
  <div class="marketplace-item-details">
    <MarketplaceItemDetail label="Название" :value="item.product.name" />
    <MarketplaceItemDetail label="Категория" :value="category" />
    <MarketplaceItemDetail label="GMID" :value="item.gmid" />
    <MarketplaceItemDetail label="Внешний артикул" :value="item.external_id" />
    <MarketplaceItemDetail label="РРЦ">
      <AppPrice :price="item.retail_price" :currency="item.currency" />
    </MarketplaceItemDetail>
    <MarketplaceItemDetail label="Статус" :value="item.status_localized" />
  </div>
</template>

<script>
import AppPrice from '@/components/AppPrice';
import MarketplaceItemDetail from '@/components/FeedItem/MarketplaceItemDetail';

export default {
  components: {
    AppPrice,
    MarketplaceItemDetail,
  },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    category() {
      if (!this.item.category) return null;

      return `${this.item.category.name} (${this.item.category.id})`;
    },
  },
};
</script>
