<template>
  <div class="marketplace-item-head">
    <MarketplaceItemImage :product="item.product" />
    <MarketplaceItemDetails :item="item" />
  </div>
</template>

<script>
import MarketplaceItemImage from '@/components/FeedItem/MarketplaceItemImage';
import MarketplaceItemDetails from '@/components/FeedItem/MarketplaceItemDetails';

export default {
  components: {
    MarketplaceItemImage,
    MarketplaceItemDetails,
  },
  props: {
    item: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.marketplace-item-head {
  margin-bottom: 30px;
  padding-bottom: 30px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 280px 1fr;
  border-bottom: 1px solid #ddd;
}
</style>
