<template>
  <div class="marketplace-item-detail" v-if="hasSlot || value">
    <div class="marketplace-item-detail__label" v-text="`${label}:`" />
    <slot>
      <div class="marketplace-item-detail__value" v-text="value" />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, required: true },
    value: { type: String, default: null },
  },
  computed: {
    hasSlot() {
      return Boolean(this.$slots.default);
    },
  },
};
</script>

<style lang="scss" scoped>
.marketplace-item-detail {
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-gap: 12px;
  font-size: 14px;

  &:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
}
</style>
