<template>
  <div class="marketplace-attribute">
    <MarketplaceAttributeName class="marketplace-attribute__name" :attribute="attribute" />
    <MarketplaceAttributeFieldObserver :attribute="attribute" v-model="_value" />
  </div>
</template>

<script>
import MarketplaceAttributeName from '@/components/FeedItem/MarketplaceAttributes/MarketplaceAttributeName';
import MarketplaceAttributeFieldObserver from '@/components/FeedItem/MarketplaceAttributes/MarketplaceAttributeFieldObserver';

export default {
  components: {
    MarketplaceAttributeName,
    MarketplaceAttributeFieldObserver,
  },
  props: {
    value: { type: Object, required: true },
    attribute: { type: Object, required: true },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.marketplace-attribute {
  margin-bottom: 16px;

  &__name {
    margin-bottom: 4px;
  }
}
</style>
