<template>
  <div class="marketplace-attribute-name">
    <div class="marketplace-attribute-name__name" :class="{ 'marketplace-attribute-name__name--required': attribute.required }" v-text="name" />
    <div class="marketplace-attribute-name__description" v-text="description" />
  </div>
</template>

<script>
export default {
  props: {
    attribute: { type: Object, required: true },
  },
  computed: {
    marketplaceAttribute() {
      return this.attribute.attribute;
    },
    canonicalAttribute() {
      return this.marketplaceAttribute.canonical_attribute;
    },
    name() {
      return this.description ? this.canonicalAttribute.name : this.marketplaceAttribute.name;
    },
    description() {
      if (this.canonicalAttribute) return this.marketplaceAttribute.name;
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.marketplace-attribute-name {
  display: block;

  &__name {
    font-size: 14px;

    &--required::before {
      content: '* ';
      color: #f00;
    }
  }

  &__description {
    font-size: 12px;
    color: #7D7D7D;
  }
}
</style>
