<template>
  <div class="feed-item">
    <v-col cols="12" v-if="hasError">
      <h1>404</h1>
    </v-col>

    <v-col class="my-15 text-center" cols="12" v-else-if="isLoading">
      <v-progress-circular size="32" indeterminate />
    </v-col>

    <v-row justify="center" v-else>
      <v-col cols="8">
        <MarketplaceItemHead :item="item" />
        <MarketplaceAttributes v-model="value" :attributes="orderedAttributes" :is-submitting="isSubmitting" @submit="updateAttributes" v-if="orderedAttributes" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import MarketplaceItemHead from '@/components/FeedItem/MarketplaceItemHead';
import MarketplaceAttributes from '@/components/FeedItem/MarketplaceAttributes/MarketplaceAttributes';

export default {
  components: {
    MarketplaceItemHead,
    MarketplaceAttributes,
  },
  data() {
    return {
      hasError: false,
      isLoading: false,
      isSubmitting: false,
      value: [],
    };
  },
  metaInfo() {
    const title = this.item.product ? `${this.item.product.name} — Инвентарь` : null;

    return {
      title,
    };
  },
  computed: {
    ...mapState('feeds', ['item']),
    ...mapGetters('marketplace_attributes', ['orderedAttributes']),
    itemId() {
      return this.$route.params.itemId;
    },
    listingId() {
      return this.$route.params.feedId;
    },
    existinAttributeValuesIds() {
      return this.item.attribute_values.map((item) => item.attribute);
    },
  },
  watch: {
    itemId: {
      immediate: true,
      async handler() {
        this.isLoading = true;
        this.hasError = false;

        try {
          await this.FETCH_ITEM_DETAILS({ listingId: this.listingId, itemId: this.itemId });
          await this.FETCH_ATTRIBUTES({ attribute_group: this.item.attribute_group?.id });
          this.setInitialAttributesValue();
        } catch (e) {
          this.hasError = true;
          this.$notify.showErrorMessage(e.response);
          if (!e.isAxiosError) throw e;
        } finally {
          this.isLoading = false;
        }
      },
    },
  },
  methods: {
    ...mapActions('feeds', ['FETCH_ITEM_DETAILS']),
    ...mapActions('marketplace_attributes', ['FETCH_ATTRIBUTES', 'UPDATE_ATTRIBUTES']),
    setInitialAttributesValue() {
      const attributesGroup = this.orderedAttributes
        .filter((item) => !this.existinAttributeValuesIds.includes(item.attribute.id))
        .map((item) => ({
          attribute: item.attribute.id,
          value: null,
        }));

      this.value = [
        ...this.item.attribute_values,
        ...attributesGroup,
      ];
    },
    async updateAttributes() {
      this.isSubmitting = true;

      try {
        await this.UPDATE_ATTRIBUTES({
          listingId: this.listingId,
          itemId: this.itemId,
          data: this.value.filter((item) => item.value),
        });
        this.$notify.showMessage({ message: 'Атрибуты успешно сохранены', color: 'success' });
      } catch (e) {
        this.$notify.showMessage({ message: 'Что-то пошло не так. Попробуйте еще раз, или напишите программистам.', color: 'error' });
        if (!e.isAxiosError) throw e;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-item {
  margin-top: 30px;
}
</style>
