<template>
  <component :is="component.component" v-bind="props" v-model="_value" />
</template>

<script>
import { VTextField, VSelect, VCheckbox } from 'vuetify/lib';

const normalizedValue = (value) => {
  if (typeof value === 'boolean') return Number(value);

  return value;
};

export default {
  props: {
    value: { type: Object, default: null },
    attribute: { type: Object, required: true },
  },
  data() {
    return {
      MAP_PROPS_TO_COMPONENT: {
        string: {
          component: VTextField,
          props: {
            hideDetails: true,
            outlined: true,
            dense: true,
          },
        },
        number: {
          component: VTextField,
          props: {
            dense: true,
            outlined: true,
            hideDetails: true,
            type: 'number',
          },
        },
        enum: {
          component: VSelect,
          props: {
            dense: true,
            outlined: true,
            hideDetails: true,
          },
        },
        boolean: {
          component: VCheckbox,
          props: {
            dense: true,
            hideDetails: true,
          },
        },
      },
    };
  },
  computed: {
    _value: {
      get() {
        return this.value.value;
      },
      set(val) {
        this.$emit('input', {
          attribute: this.value.attribute,
          value: normalizedValue(val),
        });
      },
    },
    component() {
      return this.MAP_PROPS_TO_COMPONENT[this.attribute.attribute.type];
    },
    choices() {
      return this.attribute.choices.map((choice) => ({ text: choice.value, value: choice.code }));
    },
    props() {
      const additionalProps = {};

      if (this.attribute.attribute.type === 'enum') {
        additionalProps.items = this.choices;

        if (this.attribute.attribute.is_multivalued) {
          additionalProps.multiple = true;
          additionalProps.chips = true;
        }
      }

      return { ...this.component.props, ...additionalProps };
    },
  },
};
</script>
